// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_QEGE9{position:relative;display:flex;flex-direction:column;align-items:center;background-color:#fafafa}.App__Header_2emhc{z-index:1000;width:100%;height:130px;transition:height .5s}.App__Header_2emhc.shrink_3-W98{height:65px}@media screen and (max-width:1024px){.App__Header_2emhc{height:60px}}.App__Body_158yL{width:100%}.App__Footer_3tqEq{width:100%;height:400px}.scrollToTop_1hB7L{position:fixed;right:24px;bottom:24px;width:36px;height:36px;padding:0;color:#fdfdfe;font-weight:700;font-size:18px;line-height:30px;text-align:center;background-color:#ef6530;border:1px solid #ef6530;border-radius:4px;cursor:pointer;transition:.5s ease-out;-webkit-appearance:none}.scrollToTop_1hB7L:focus,.scrollToTop_1hB7L:hover{opacity:.6;-webkit-appearance:none}.fade-enter_3Esny,.fade-leave-to_rX2vW{opacity:0}.fade-enter-to_i5UCn,.fade-leave_12Z_c{opacity:1}", ""]);
// Exports
exports.locals = {
	"App": "App_QEGE9",
	"App__Header": "App__Header_2emhc",
	"shrink": "shrink_3-W98",
	"App__Body": "App__Body_158yL",
	"App__Footer": "App__Footer_3tqEq",
	"scrollToTop": "scrollToTop_1hB7L",
	"fade-enter": "fade-enter_3Esny",
	"fade-leave-to": "fade-leave-to_rX2vW",
	"fade-enter-to": "fade-enter-to_i5UCn",
	"fade-leave": "fade-leave_12Z_c"
};
module.exports = exports;
