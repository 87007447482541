















import Vue from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: Object as () => { statusCode: number; message: string },
      required: true
    }
  }
})
