





















import Vue from 'vue'
const HeaderContainer = () => import('@/containers/Header')
const FooterContainer = () => import('@/containers/Footer')
import { scrollWithDuration } from '@/lib/scroller'

export default Vue.extend({
  components: {
    HeaderContainer,
    FooterContainer
  },
  data() {
    return {
      screenPosition: 0,
      showScrollToTop: false
    }
  },
  computed: {
    isHeaderShrink(): boolean {
      return this.$route.name !== 'index'
    }
  },
  mounted() {
    if (window) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    scrollToTop() {
      const targetElement = document.scrollingElement || document.documentElement
      if (targetElement) {
        scrollWithDuration(targetElement)
        this.showScrollToTop = false
      }
    },
    handleScroll() {
      const position = window.scrollY
      if (this.screenPosition >= position) {
        this.showScrollToTop = true
      } else {
        this.showScrollToTop = false
      }
      this.screenPosition = position
    }
  }
})
